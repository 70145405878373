import Link from "next/link"
import { useRouter } from "next/router"
import {useTranslation} from "next-i18next";

import config from "site.config"

export function LocaleSwitcher() {
    const { locales, asPath, locale: currentLocale } = useRouter()
    const { t } = useTranslation('common');
    const changeTo = currentLocale === 'en' ? 'cs' : 'en';

    return (
            <ul className="nav-lang">
            {locales.map((locale) => {
                if(locale !== currentLocale) {
                    return (
                        <li key={"locale-"+locale}>
                            <Link href={'/'+locale} key={locale} locale={locale} title={t('change_locale', { changeTo })} passHref>
                                {config.locales[locale]}
                            </Link>
                        </li>
                    );
                }
            })}
        </ul>
    )
}
