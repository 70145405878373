import { DrupalClient } from "next-drupal"


var publicOptions = {
  debug: process.env.NODE_ENV !== 'production',
  throwJsonApiErrors: process.env.NODE_ENV !== 'production',
  frontPage: '/',
}

export const drupalPublic = new DrupalClient(process.env.NEXT_PUBLIC_DRUPAL_API_URL, publicOptions)


var internalOptions = {
  debug: process.env.NODE_ENV !== 'production',
  throwJsonApiErrors: process.env.NODE_ENV !== 'production',
  frontPage: '/',
}

export const drupalInternal = new DrupalClient(process.env.NEXT_INTERNAL_DRUPAL_API_URL, internalOptions)
