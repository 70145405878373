import {DrupalMenuLinkContent} from "next-drupal";
import {useRouter} from "next/router";
import Link from "next/link";
import React, { useEffect } from "react"
import classNames from "classnames";

export function Menu({ items }: { items: DrupalMenuLinkContent[] }) {
    useEffect(() => {
        const homeLink = document.querySelector('.nav-main > li a');
        const targetEl = document.querySelector('#campaigns');
        if (homeLink) {
            homeLink.addEventListener('click', (e) => {
                if (targetEl) {
                    e.preventDefault();
                    targetEl.scrollIntoView({ behavior: "smooth" });
                } else {
                    localStorage.setItem("get_back", "true");
                }
            });
        }
    }, []);

    return (
        <ul className="nav-main">
            {items.map((item) => (
                <MenuLink link={item} key={item.id} />
            ))}
        </ul>
    )
}

function MenuLink({ link }: { link: DrupalMenuLinkContent }) {
    const { locale, asPath, pathname } = useRouter()
    /*let path_menu = '/'+locale+asPath
    if (path_menu.slice(-1) == '/' ){
        path_menu = path_menu.slice(0, -1)
    }*/
    let path_menu = '/' + locale + (asPath == '/' ? '' : asPath);


    return (
        <li>
            <Link href={link.url} passHref className={classNames(
                "py-4 hover:underline text-sm md:text-base",
                link.url === path_menu ? "active" : ""
            )}
            >
                {link.title}
            </Link>
        </li>
    )
}
