import React, { useEffect } from "react"
import { useRouter } from "next/router"
import { DrupalMenuLinkContent } from "next-drupal"
import Link from "next/link"
import { useTranslation } from "next-i18next";
import classNames from "classnames"

import { LocaleSwitcher } from "components/layout/locale-switcher"
import { Menu } from "components/layout/menu"

interface NavbarProps {
    links: DrupalMenuLinkContent[],
    pathName: string
}

export function Navbar({ links, pathName, ...props }: NavbarProps) {
    const { locale } = useRouter()
    const { t } = useTranslation('navbar')
    const [showMenu, setShowMenu] = React.useState<Boolean>(false)

    useEffect(() => {
        document.body.classList.remove("menu_transparent");
    }, [])

    return (
        /* eslint-disable @next/next/no-img-element */
        <header>
            <div className="container">
                <nav>

                    <LocaleSwitcher />

                    <div className="nav-logo">
                        <Link href="/" locale={locale} passHref><img src="/images/layout/logo.svg" alt={t("ogilvy_logo")}/></Link>
                    </div>

                    {links ? <Menu items={links} /> : null}

                    <div className={classNames("nav-toggle",{"active": showMenu})} onClick={() => setShowMenu(!showMenu)}>
                        <span></span>
                        <span></span>
                    </div>

                    <div className={classNames("nav-mob",{"active": showMenu,})}>
                        {links ? <Menu items={links} /> : null}
                        <LocaleSwitcher />
                    </div>

                </nav>
            </div>
        </header>
        /* eslint-enable @next/next/no-img-element */
    )
}

