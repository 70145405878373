import React from "react"
import classNames from "classnames"
import { useTranslation } from "next-i18next";
import Cookies from 'js-cookie';

import config from "site.config"

interface CookieConsentProps {
    showCookieBar: Boolean,
    cookie_names: {},
    closeFunction
}

export function CookieConsent({showCookieBar, cookie_names, closeFunction, ...props }: CookieConsentProps) {
    const { t } = useTranslation('cookie')

    const setCookieConsentCookie = (cookie_type: string, value) => {
        Cookies.set(cookie_names[cookie_type],value,{expires: Number(config.cookie_consent_expire)});
    }

    const setCookieConsentAll = () => {
        for (const [cookie_type, cookie_name] of Object.entries(cookie_names)) {
            let element = document.querySelector('#cookies-bar--'+cookie_type) as HTMLInputElement;
            element.checked = true;
            setCookieConsentCookie(cookie_type, true);
            closeFunction();
        }
    }

    const setCookieConsentNone = () => {
        for (const [cookie_type, cookie_name] of Object.entries(cookie_names)) {
            let element = document.querySelector('#cookies-bar--'+cookie_type) as HTMLInputElement;
            element.checked = false;
            setCookieConsentCookie(cookie_type, false);
            closeFunction();
        }
    }

    return (
        <div className="cookie-bar-consent">
            <div id="cookies-bar" className={classNames("cookies-bar", showCookieBar == true && "cookies-bar-open")}>
                <div className="container">
                    <button id="cookies-bar--btn-close" className="cookies-bar--btn-close" onClick={closeFunction}>
                        <svg id="close-icon" viewBox="0 0 14.6 14.6" width="100%" height="100%">
                            <path fill="none" stroke="currentColor" strokeWidth="1" d="M.3.3l14 14m0-14l-14 14"></path>
                        </svg>
                    </button>

                    <p className="cookies-bar--intro" dangerouslySetInnerHTML={{__html: t('cookie.content') }}></p>
                    <div className="cookies-bar--categories">
                        {Object.keys(cookie_names).map((cookie_type, index) => {
                            let cookie_name = cookie_names[cookie_type];
                            return (
                            <div className="cookies-bar--col" key={"cookie-consent-"+cookie_type}>
                            <p className="cookies-bar--label">{t( 'cookie.'+cookie_type) }</p>
                            <label className="cookies-bar--checkbox">
                            <input type="checkbox"  name={"cookies-bar--"+cookie_type} id={"cookies-bar--"+cookie_type} defaultChecked={Cookies.get(cookie_name) == 'true'}  onClick={(e) => {setCookieConsentCookie(cookie_type, e.currentTarget.checked)}} />
                            <span className="cookies-bar--line">&nbsp;</span>
                            <span className="cookies-bar--dot">&nbsp;</span>
                            <p className="cookies-bar--option-checked">{t('cookie.ON')}</p>
                            <p className="cookies-bar--option-default">{t('cookie.OFF')}</p>
                            </label>
                            </div>
                            );
                        })
                        }
                        <div className="cookies-bar--col">
                            <button className="cookies-bar--btn" id="cookies-bar--accept-all" onClick={setCookieConsentAll}>{t('cookie.accept_all')}</button>
                            <button className="cookies-bar--btn" id="cookies-bar--refuse-all" onClick={setCookieConsentNone}>{t('cookie.refuse_all')}</button>
                            <button className="cookies-bar--btn" id="cookies-bar--selection" onClick={closeFunction} >{t('cookie.selection')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
